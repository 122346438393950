import dashboardIcon from "../assets/speed_black_24dp.svg";
import Icon from "@ant-design/icons"

import { APP_PREFIX_PATH } from "configs/AppConfig";

import {
  DashboardIcon,
  AccountManagementIcon,
  TaskManagementIcon,
  CMSIcon,
  InventoryManagementIcon,
  InquiryManagementIcon,
  ContractManagementIcon,
  MasterIcon,
  MessageIcon,
  // UserManagementIcon,
  // ItemAndServicesIcon,
  // DeliveryManagementIcon,
  // FinanceManagementIcon,
  // ReportIcon,
  // AttendanceManagementIcon,
} from "./svgIcons";

const dashBoardNavTree = [
  {
    key: "d",
    path: "",
    title: " ",
    icon: "",
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/dashboard/finance`,
        title: "Dashboard",
        icon: DashboardIcon,
        breadcrumb: false,
        submenu: [
          // {
          //   key: "finance",
          //   path: `${APP_PREFIX_PATH}/dashboard/finance`,
          //   title: "Finance",
            
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "sales",
          //   path: `${APP_PREFIX_PATH}/dashboard/sales`,
          //   title: "Sales",
            
          //   breadcrumb: false,
          //   submenu: [],
          // }
        ],
      },
      {
        key: "customer-management",
        path: `${APP_PREFIX_PATH}/customer-management`,
        title: "Customer Management",
        icon: AccountManagementIcon,
        breadcrumb: false,
        submenu: [
          {
            key: "customer-accounts",
            path: `${APP_PREFIX_PATH}/customer-management/customer-accounts`,
            title: "Customer Accounts",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "job-sites",
            path: `${APP_PREFIX_PATH}/customer-management/job-sites`,
            title: "Job Sites",
           
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "customer-users-all",
          //   path: `${APP_PREFIX_PATH}/customer-management/customer-users-all`,
          //   title: "Customer Users",
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
      // {
      //   key: "inquiry-management",
      //   path: `${APP_PREFIX_PATH}/inquiry-management`,
      //   title: "Inquiry Management",
      //   icon: InquiryManagementIcon,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "inquiry-list",
      //       path: `${APP_PREFIX_PATH}/inquiry-management/inquiry-list`,
      //       title: "Inquiry List",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "chats",
      //       path: `${APP_PREFIX_PATH}/inquiry-management/chats`,
      //       title: "Chats",
           
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
      {
        key: "contract-management",
        path: `${APP_PREFIX_PATH}/contract-management`,
        title: "Contract Management",
        icon: ContractManagementIcon,
        breadcrumb: false,
        submenu: [
          {
            key: "job-quotations",
            path: `${APP_PREFIX_PATH}/contract-management/quotations`,
            title: "Job Quotations",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "contract-list",
            path: `${APP_PREFIX_PATH}/contract-management/contracts`,
            title: "Contract List",
            
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "finance-reports",
          //   path: `${APP_PREFIX_PATH}/contract-management/finance-reports`,
          //   title: "Finance Reports",
            
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
      {
        key: "task-management",
        path: `${APP_PREFIX_PATH}/task-management`,
        title: "Task Management",
        icon: TaskManagementIcon,
        breadcrumb: false,
        submenu: [
          {
            key: "task",
            path: `${APP_PREFIX_PATH}/task-management/task`,
            title: "Customer Tasks",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "schedule",
            path: `${APP_PREFIX_PATH}/task-management/schedule`,
            title: "Schedule",
           
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "cms",
        path: `${APP_PREFIX_PATH}/cms`,
        title: "CMS",
        icon: CMSIcon,
        breadcrumb: false,
        submenu: [
          {
            key: "terms-&-conditions",
            path: `${APP_PREFIX_PATH}/cms/terms-&-conditions`,
            title: "Terms & Conditions",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "privacy-policy",
            path: `${APP_PREFIX_PATH}/cms/privacy-policy`,
            title: "Privacy Policy",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "notification-&-alert",
            path: `${APP_PREFIX_PATH}/cms/notification-&-alert`,
            title: "Notification & Alert",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "contact-details",
            path: `${APP_PREFIX_PATH}/cms/contact-details`,
            title: "Contact Details",
            
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      // {
      //   key: "inventory-management",
      //   path: `${APP_PREFIX_PATH}/inventory-management`,
      //   title: "Inventory Management",
      //   icon: InventoryManagementIcon,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "product-category",
      //       path: `${APP_PREFIX_PATH}/inventory-management`,
      //       title: "Product Category",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "products",
      //       path: `${APP_PREFIX_PATH}/inventory-management`,
      //       title: "Products",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "purchase-orders",
      //       path: `${APP_PREFIX_PATH}/inventory-management`,
      //       title: "Purchase Orders",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "transactions",
      //       path: `${APP_PREFIX_PATH}/inventory-management`,
      //       title: "Transactions",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "stockout",
      //       path: `${APP_PREFIX_PATH}/inventory-management`,
      //       title: "Stockout",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "master-data",
      //       path: `${APP_PREFIX_PATH}/inventory-management`,
      //       title: "Master Data",
            
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
      {
        key: "masters",
        path: `${APP_PREFIX_PATH}/masters`,
        title: "Masters",
        icon: MasterIcon,
        breadcrumb: false,
        submenu: [
          {
            key: "task-category",
            path: `${APP_PREFIX_PATH}/masters/task-categories`,
            title: "Task Category",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "gst",
            path: `${APP_PREFIX_PATH}/masters/gst`,
            title: "GST",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;